import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, Grid, IconButton, Paper, Slide, Tooltip, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useFirstMountState} from 'react-use';
import {DocumentSigningMobile} from './DocumentSigningMobile';
// eslint-disable-next-line import/no-cycle
import {DocumentSigningMobileTraining} from './DocumentSigningMobileTraining';
import {PdfViewer} from './PdfViewer';
import SwipeableEdgeDrawer from '../../../../../components/swipable-edge/SwipableEdge';
import {useMemoizedCreateSelector} from '../../../../../hooks/use-memoized-create-selector';
import {importLocaleBundle} from '../../../../../lib/i18next';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {DOCUMENT_STATUSES, DOCUMENT_TYPES} from '../../../../../v1/config/constants/documentConstants';
import {resolveRoute} from '../../../../../v1/navigation/resolveRoute';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {AccountingSelector} from '../../../../company-profile/modules/accounting/store/accounting.selector';
import {PAST_YEAR} from '../../../../company-profile/modules/accounting/utils/constants';
import DepositOfAccountsPaymentModal from '../../../../company-profile/modules/formalities/deposit-of-accounts/components/DepositOfAccountsPaymentModal';
import {DEPOSIT_OF_ACCOUNTS_PAY_MODAL_DELAY} from '../../../../company-profile/modules/formalities/utils/constants';
import {FreelancerSelectors} from '../../../../freelancer';
import {InsuranceActions} from '../../../../insurance/store/insurance.action';
import {InsuranceSelectors} from '../../../../insurance/store/insurance.selector';
import {InsuranceStatus} from '../../../../insurance/utils/constants';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {SignatureSelectors} from '../../../../signature/store/signature.selector';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {DocumentActions} from '../../../store/document.action';
import {DatabaseSelectors} from '../../database/store/database.selector';
import {SigningActions} from '../store/signing.action';
import {SigningSelectors} from '../store/signing.selector';

importLocaleBundle('document');

const TIMEOUT_DELAY = 400;

const SIGNATURE_OFFSETS_BY_DOCUMENT_TYPE = {
    PARTNERS_LIST: 0.87,
    DNC: 0.52,
    DOMICILIATION_CERTIFICATE: 0.55,
    REGISTRATION_STATUS: 0.91,
    HOME_RENTAL_CONTRACT: 0.96,
    BANK_DELEGATED_ACCESS: 0.49,
    NON_REJECTION_ACRE_CERT: 0.49,
    CAPITAL_DEPOSIT_STATUS: 0.97,
    OGI_FRANCE_MEMBERSHIP: 0.35,
    TAX_OPTION: 0.54,
    SALARIED_PRESIDENT_CERTIFICATE: 0.34,
    POWER_OF_ATTORNEY_REGISTRATION_MANDATE: 0.75,
    HIWAY_ACCOUNTING_MISSION_LETTER: 0.25,
};

export const DocumentSigning = ({MainActionComponent, type, isLoadingNextStep}) => {
    const {t} = useTranslation('document');

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const dispatch = useDispatch();
    const isFirstMount = useFirstMountState() ?? true;

    const pdfViewerRef = useRef();
    const pdfViewerContainerRef = useRef();

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const signatureUrl = useSelector(SignatureSelectors.selectUrl);
    const isSigned = useSelector(SigningSelectors.selectCurrentDocumentIsSigned);
    const documentId = useSelector(SigningSelectors.selectCurrentDocumentId);
    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);
    const document = useMemoizedCreateSelector(DatabaseSelectors.createDocumentByIdSelector, documentId);
    const isSigningAdditionalDocuments = useSelector(SigningSelectors.selectIsSigningAdditionalDocuments);
    const documents = useSelector(DatabaseSelectors.selectDocuments);
    const areAllDocumentsSigned = useMemo(() => {
        return isSigningAdditionalDocuments
            && !!Object.values(documents).length
            && Object.values(documents).every(document => document.status === DOCUMENT_STATUSES.SIGNED);
    }, [isSigningAdditionalDocuments, documents]);

    const isDownloading = useLoadingState(LoadingTypes.DOWNLOAD_DOCUMENT);
    const annualAccounts = useSelector(AccountingSelector.selectFinancialStatements)?.[PAST_YEAR];

    const isSigningDepositOfAccounts = Object.values(annualAccounts?.generatedDocuments
                                        ?? {}).find(doc => doc.id === documentId);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const insurance = useSelector(InsuranceSelectors.selectInsurance);
    const shouldSignInsurance = !!insurance?.insuranceType
            && document?.type === DOCUMENT_TYPES.INSURANCE_GROUP_INSURANCE_DIRECT_DEBIT_MANDATE;

    const signInsurance = () => {
        dispatch(InsuranceActions.setInsurance({
            freelancerId: freelancer?.id,
            companyId: freelancer?.defaultCompanyId,
            data: {status: InsuranceStatus.SIGNED},
        }));
    };

    const onNextClick = () => {
        if (shouldSignInsurance) {
            signInsurance();
        }

        dispatch(AnimationActions.storeNextDispatch(
            SigningActions.openNextDocument(),
        ));
        dispatch(AnimationActions.setIsAnimationActive(false));
    };

    const handleLoadSuccess = () => {
        if (isSigned && !isSigning && pdfViewerRef.current) {
            setTimeout(() => {
                const height = pdfViewerRef.current.size.content.height;
                const containerHeightOffset = pdfViewerContainerRef.current.offsetHeight / 2;
                let documentOffset = SIGNATURE_OFFSETS_BY_DOCUMENT_TYPE[document?.type];

                if (!document) {
                    // For contract
                    documentOffset = 0.95;
                }

                let offset = height * documentOffset;
                offset -= containerHeightOffset;

                pdfViewerRef.current.scrollTo(0, offset || pdfViewerRef.current.size.content.height, 1500, {
                    easing: position => {
                        return (position === 1) ? 1 : -Math.pow(2, -10 * position) + 1;
                    },
                });
            }, 500);
        }
    };

    useEffect(() => {
        if (isSigningDepositOfAccounts && areAllDocumentsSigned) {
            setTimeout(() => {
                dispatch(UiActions.setActiveModal(ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT, true));
                dispatch(UiActions.setModalData(ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT, {isOpenFromDashboard: false}));
            }, DEPOSIT_OF_ACCOUNTS_PAY_MODAL_DELAY);
        }
    }, [dispatch, isSigningDepositOfAccounts, areAllDocumentsSigned]);

    if (!isMobileSize) {
        return (
            <Box display="grid" gridTemplateColumns="1fr 238px" gap={2} minHeight="100%">
                <Slide
                    in={isAnimationActive}
                    direction="up"
                    onEntered={onEndedAnimation}
                    onExited={onEndedAnimation}
                >
                    <div ref={pdfViewerContainerRef}>
                        <PdfViewer scrollRef={pdfViewerRef} onLoadSuccess={handleLoadSuccess} type={type} />
                    </div>
                </Slide>

                <Box sx={{height: 'min-content'}}>
                    <Slide
                        in={!isFirstMount || true}
                        direction="left"
                        timeout={TIMEOUT_DELAY}
                    >
                        <div>
                            <Paper radius={8} elevation={2} sx={{p: 2}}>
                                <Typography variant="h5">
                                    {t('signing.sidebarTitle')}
                                </Typography>

                                <Alert
                                    severity={isSigned ? 'success' : 'warning'}
                                    sx={{
                                        mt: 1,
                                        borderRadius: 3,
                                        color: isSigned ? 'rgba(0, 0, 0, 0.6)' : 'warning.dark',
                                    }}
                                    icon={isSigned
                                        ? <CheckCircleIcon sx={{color: theme => theme.palette.buttonSuccess.main}} />
                                        : <HourglassTopIcon sx={{color: 'warning.dark'}} />}
                                >
                                    {!isSigned
                                        ? t('signing.awaitingSignature')
                                        : MainActionComponent ? t('signing.contractSigned') : t('signing.documentSigned')
                                    }
                                </Alert>

                                {MainActionComponent
                                    ? React.createElement(MainActionComponent)
                                    : areAllDocumentsSigned
                                        ? (
                                            <>
                                                {isSigningDepositOfAccounts && (
                                                    <LoadingButton
                                                        sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                                                        variant="contained"
                                                        color="info"
                                                        size="small"
                                                        loading={isSigning || isLoadingNextStep || !documentId}
                                                        loadingPosition="start"
                                                        onClick={() => {
                                                            dispatch(UiActions.setActiveModal(
                                                                ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT,
                                                                true,
                                                            ));
                                                        }}
                                                        fullWidth
                                                    >
                                                        {t('companies:formalities.depositOfAccounts.payButton')}
                                                    </LoadingButton>
                                                )}
                                                {!isSigningDepositOfAccounts && (
                                                <Link
                                                    to={resolveRoute(RoutePaths.DASHBOARD)}
                                                    style={{textDecoration: 'none', color: 'inherit', alignSelf: 'end'}}
                                                >
                                                    <LoadingButton
                                                        sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                                                        variant="contained"
                                                        color="info"
                                                        size="small"
                                                        loading={isSigning || isLoadingNextStep || !documentId}
                                                        loadingPosition="start"
                                                        onClick={() => {
                                                            isSigned
                                                                ? onNextClick()
                                                                : dispatch(SigningActions.signCurrentDocument());
                                                        }}
                                                        fullWidth
                                                    >
                                                        Retour au Dashboard
                                                    </LoadingButton>
                                                </Link>
                                                )}
                                            </>
                                        )
                                        : (
                                            <LoadingButton
                                                sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                                                startIcon={isSigned ? null : <EditIcon />}
                                                endIcon={isSigned ? <ArrowForwardIcon /> : null}
                                                variant="contained"
                                                color={isSigned ? 'buttonSuccess' : 'secondary'}
                                                size="small"
                                                loading={isSigning || isLoadingNextStep || !documentId}
                                                loadingPosition="start"
                                                onClick={() => {
                                                    isSigned
                                                        ? onNextClick()
                                                        : dispatch(SigningActions.signCurrentDocument());
                                                }}
                                                fullWidth
                                            >
                                                {isSigned ? t('signing.next') : t('signing.signButton')}
                                            </LoadingButton>
                                        )
                                }

                                {MainActionComponent && isSigned && signatureUrl && (
                                    <LoadingButton
                                        sx={{py: 0.75, borderRadius: 2, mt: 1}}
                                        startIcon={<DownloadIcon />}
                                        variant="outlined"
                                        size="small"
                                        loading={isDownloading || !documentId}
                                        loadingPosition="start"
                                        onClick={() => {
                                            dispatch(DocumentActions.getDocument(documentId, true));
                                        }}
                                        fullWidth
                                    >
                                        {t('signing.downloadPdf')}
                                    </LoadingButton>
                                )}
                            </Paper>
                        </div>
                    </Slide>

                    <Slide
                        in={signatureUrl}
                        direction="up"
                        timeout={TIMEOUT_DELAY * 2}
                    >
                        <div>
                            <Paper radius={8} elevation={2} sx={{p: 2, mt: 2}}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h5">
                                        {t('signing.mySignatureTitle')}
                                    </Typography>

                                    <Tooltip title={t('signing.changeSignatureTooltip')} placement="top" arrow>
                                        <IconButton
                                            sx={{
                                                '&:hover': {
                                                    color: 'primary.light',
                                                },
                                            }}
                                            size="small"
                                            onClick={() => {
                                                dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true));
                                            }}
                                        >
                                            <PublishedWithChangesIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                <Box sx={{mt: 2}}>
                                    <img
                                        src={signatureUrl}
                                        alt="Signature"
                                        style={{width: '100%'}}
                                    />
                                </Box>
                            </Paper>
                        </div>
                    </Slide>
                </Box>
                {isSigningDepositOfAccounts && (
                    <DepositOfAccountsPaymentModal paymentInfo={annualAccounts?.payLinkInfo} />
                )}
            </Box>
        );
    }

    return (
        <>
            <Grid container justifyContent="space-between" flexDirection="column">
                <div ref={pdfViewerContainerRef}>
                    <PdfViewer scrollRef={pdfViewerRef} onLoadSuccess={handleLoadSuccess} type={type} />
                </div>
            </Grid>
            {type === 'training' ? (
                <SwipeableEdgeDrawer
                    drawerContent={
                        <DocumentSigningMobileTraining isLoadingNextStep={isLoadingNextStep} />
                    }
                    height="135px"
                />
            ) : (
                <SwipeableEdgeDrawer
                    drawerContent={(
                        <DocumentSigningMobile
                            MainActionComponent={MainActionComponent}
                            areAllDocumentsSigned={areAllDocumentsSigned}
                            isLoadingNextStep={isLoadingNextStep}
                            onNextClick={onNextClick}
                        />
                    )}
                    height={MainActionComponent ? (isSigned ? '270px' : '185px') : '50px'}
                />
            )}
        </>
    );
};

DocumentSigning.propTypes = {
    MainActionComponent: PropTypes.any,
    type: PropTypes.string,
    isLoadingNextStep: PropTypes.bool,
};

DocumentSigning.defaultProps = {
    MainActionComponent: null,
    type: 'onboarding',
    isLoadingNextStep: false,
};
