import FilePresentIcon from '@mui/icons-material/FilePresent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import DOAFileDropdownOptions from './DOAFileDropdownOptions';

/*
 * TODO: When we start standardizing components,
 * we should make turn this component into reusable
 * component for Files.
 *
 */
export const DOAFile = ({
    fileName,
    onView,
    onDownload,
    onReplace,
    onDelete,
    isDeletingPossible,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const buttonRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const isEditable = isDeletingPossible;


    const handleDropdownClick = event => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };

    if (!isMobileSize) {
        return (
            <>
                <Box
                    ref={buttonRef}
                    sx={{
                        mb: 2,
                        fontSize: theme => theme.typography.pxToRem(13),
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer',
                        p: 1,
                        backgroundColor: isOpen ? 'primary_v2.dark' : 'primary_v2.main',
                        color: 'white',
                        borderRadius: '8px',
                        width: '100%',
                    }}
                    onClick={handleDropdownClick}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 2,
                        flex: 1,
                    }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1.8,
                        }}
                        >
                            <FilePresentIcon />
                            <Box component="span" sx={{wordBreak: 'break-word'}}>
                                {fileName}
                            </Box>
                        </Box>
                        {isOpen ? (
                            <KeyboardArrowUpIcon sx={{ml: 'auto'}} />
                        ) : (
                            <KeyboardArrowDownIcon sx={{ml: 'auto'}} />
                        )}
                    </Box>
                </Box>
                <DOAFileDropdownOptions
                    isOpen={isOpen}
                    onViewFile={onView}
                    onDownloadFile={onDownload}
                    onReplaceFile={onReplace}
                    onDeleteFile={onDelete}
                    isDeletingPossible={isDeletingPossible}
                    parentWidth={buttonRef?.current?.clientWidth}
                    anchorEl={anchorEl}
                    isEditable={isEditable}
                    onClose={() => setIsOpen(false)}
                />
            </>
        );
    }

    return (
        <>
            <Box
                sx={{
                    mb: 2,
                    fontSize: theme => theme.typography.pxToRem(13),
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    p: 1,
                    backgroundColor: isOpen ? 'primary_v2.dark' : 'primary_v2.main',
                    color: 'white',
                    borderRadius: '8px',
                    width: '100%',
                }}
                onClick={handleDropdownClick}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    flex: 1,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.8,
                    }}
                    >
                        <FilePresentIcon />
                        {fileName}
                    </Box>
                    {isOpen ? (
                        <KeyboardArrowUpIcon sx={{ml: 'auto'}} />
                    ) : (
                        <KeyboardArrowDownIcon sx={{ml: 'auto'}} />
                    )}
                </Box>
            </Box>
            <DOAFileDropdownOptions
                isOpen={isOpen}
                onViewFile={onView}
                onDownloadFile={onDownload}
                onReplaceFile={onReplace}
                onDeleteFile={onDelete}
                isDeletingPossible={isDeletingPossible}
                parentWidth={buttonRef?.current?.clientWidth}
                isEditable={isEditable}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
};

DOAFile.propTypes = {
    fileName: PropTypes.string.isRequired,
    onView: PropTypes.func,
    onDownload: PropTypes.func,
    onReplace: PropTypes.func,
    onDelete: PropTypes.func,
    isDeletingPossible: PropTypes.bool.isRequired,
};

DOAFile.defaultProps = {
    onView: () => {},
    onDownload: () => {},
    onReplace: () => {},
    onDelete: () => {},
};

export default DOAFile;
