import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import EuroIcon from '@mui/icons-material/Euro';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {Box, Stack, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {roadmapStepsBgColor} from './utils';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import {useSimulationData} from '../../utils/useSimulationData';

const MobileRoadmapStep = ({month, Icon, children}) => {
    const {t} = useTranslation('revenueSimulator');
    return (
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Icon color="primary" />
                <Box sx={{fontFamily: 'Barlow', fontWeight: 500}}>
                    {typeof month === 'number' ? `${t('roadmap.month')} ${month}` : month}
                </Box>
            </Box>
            <Box sx={{flex: 1, borderBottom: '1px dashed', borderColor: 'v2.blueGray.300', mb: -1}} />
            <Box>{children}</Box>
        </Box>
    );
};

MobileRoadmapStep.propTypes = {
    month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    Icon: PropTypes.elementType.isRequired,
};

const EventTab = ({children, color}) => {
    return (
        <Box
            sx={{
                p: 1,
                backgroundColor: color,
                textAlign: 'center',
                borderRadius: '8px',
                fontSize: theme => theme.typography.pxToRem(10),
                fontWeight: 500,
            }}
        >
            {children}
        </Box>
    );
};

EventTab.propTypes = {
    color: PropTypes.string,
};

EventTab.defaultProps = {
    color: 'v2.amber.50',
};

export const MobileRoadmapSteps = () => {
    const {t} = useTranslation('revenueSimulator');
    const {pxToRem} = useTheme().typography;
    const simulation = useSimulationData();
    const months = simulation.simulationInput.professionalInfo.monthsToRestoreTravelSubvention || 0;
    const ARCE = simulation.simulationOutput.ACRE;
    const withHiway = simulation.simulationOutput.compensationRoadmap.graph.withHiway;

    return (
        <Box
            sx={{
                backgroundColor: roadmapStepsBgColor,
                borderRadius: '8px',
                px: 2,
                py: pxToRem(12),
                fontSize: pxToRem(12),
                mt: 3,
                strong: {
                    fontWeight: 600,
                },
            }}
        >
            <Stack spacing={pxToRem(12)}>
                <EventTab color="v2.blueGray.50">CDI</EventTab>
                <MobileRoadmapStep month={0} Icon={RocketLaunchIcon}>
                    <EurAmount amount={withHiway[0]} /> {t('income.perMonth')}
                </MobileRoadmapStep>
                {months > 0 && (
                    <>
                        <EventTab>{t('roadmap.portageSalarial')}</EventTab>
                        {months === 1 && (
                            <MobileRoadmapStep month={1} Icon={BubbleChartIcon}>
                                <EurAmount amount={withHiway[1]} /> {t('income.perMonth')}
                            </MobileRoadmapStep>
                        )}
                        {months === 4 && (
                            <MobileRoadmapStep month={t('roadmap.months1to4')} Icon={BubbleChartIcon}>
                                <EurAmount amount={withHiway[1]} /> {t('income.perMonth')}
                            </MobileRoadmapStep>
                        )}
                    </>
                )}
                <EventTab>{t('roadmap.freelancing')}</EventTab>
                <MobileRoadmapStep month={1 + months} Icon={RocketLaunchIcon}>
                    {t('roadmap.companyCreation.mobile')}
                </MobileRoadmapStep>
                <MobileRoadmapStep month={2 + months} Icon={EuroIcon}>
                    {t('roadmap.arce')} <strong>#1 • </strong>
                    <EurAmount amount={ARCE / 2} />
                </MobileRoadmapStep>
                <MobileRoadmapStep month={8 + months} Icon={EuroIcon}>
                    {t('roadmap.arce')} <strong>#2 • </strong>
                    <EurAmount amount={ARCE / 2} />
                </MobileRoadmapStep>
            </Stack>
        </Box>
    );
};
