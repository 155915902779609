import {Divider, FormLabel, Grid, MenuItem, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {CityAutocomplete} from '../../../../../components/CityAutocomplete';
import {CARE_COMPANY_TAX_SYSTEMS} from '../../../../../v1/app/company/company.consts';
import {CareJobTypes, ContactSource, StartContractTypeCare} from '../../../utils/constants';
import {UserSelect} from '../../user-select/components/UserSelect';

export const AdditionalTopCareFields = ({control, isLoading, errors}) => {
    const {t} = useTranslation('createUser');


    const jobTypes = Object.values(CareJobTypes);
    const taxSystemOptions = useMemo(() => {
        return Object.values(CARE_COMPANY_TAX_SYSTEMS).map(taxSystem => (
            <MenuItem value={taxSystem} key={taxSystem}>
                {t(`taxSystems.${taxSystem}`, {ns: 'companies'})}
            </MenuItem>
        ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Grid item xs={12} lg={6}>
                <UserSelect
                    data-cy="coach"
                    label={t('coach')}
                    name="coach"
                    error={!!errors.coach}
                    helperText={errors.coach?.message}
                    control={control}
                    disabled={isLoading}
                />
            </Grid>

            <Grid item xs={12} lg={6}>
                <Controller
                    control={control}
                    name="jobType"
                    render={({field}) => (
                        <TextField
                            data-cy="jobType"
                            variant="filled"
                            label={t('jobType') + '*'}
                            disabled={isLoading}
                            fullWidth
                            error={!!errors.jobType}
                            helperText={errors.jobType?.message}
                            select
                            sx={{
                                textTransform: 'capitalize',
                            }}
                            {...field}
                        >
                            {jobTypes.map(jobType => {
                                return (
                                    <MenuItem
                                        sx={{
                                            textTransform: 'capitalize',
                                        }}
                                        key={jobType}
                                        value={jobType}
                                    >
                                        {t(`careJobTypes.${jobType}`)}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    )}
                />
            </Grid>

            <Grid item xs={12} lg={12}>
                <Controller
                    control={control}
                    name="taxSystem"
                    render={({field}) => (
                        <TextField
                            variant="filled"
                            label={t('tax')}
                            disabled={isLoading}
                            fullWidth
                            error={!!errors.taxSystem}
                            helperText={errors.taxSystem?.message}
                            select
                            {...field}
                        >
                            {taxSystemOptions}
                        </TextField>
                    )}
                />
            </Grid>
        </>
    );
};

AdditionalTopCareFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
};

AdditionalTopCareFields.defaultProps = {
    isLoading: false,
    errors: null,
};

export const AdditionalCareFields = function ({control, isLoading, startContractType, errors}) {
    const {t} = useTranslation('createUser');

    return (
        <>
            <Divider variant="dashed" sx={{my: 4}} />

            <FormLabel variant="black" sx={{mb: 2, display: 'block'}}>
                {t('coachingInformation')}
            </FormLabel>

            <Grid container spacing={3}>

                <Grid item xs={12} lg={12}>
                    <CityAutocomplete
                        data-cy="city"
                        label={t('city')}
                        name="city"
                        control={control}
                        disabled={isLoading}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="contactSource"
                        render={({field}) => (
                            <TextField
                                data-cy="contactSource"
                                variant="filled"
                                label={t('contactSource')}
                                disabled={isLoading}
                                fullWidth
                                select
                                error={!!errors.contactSource}
                                helperText={errors.contactSource?.message}
                                {...field}
                            >
                                {Object.values(ContactSource).map(contactSource => {
                                    return (
                                        <MenuItem key={contactSource} value={contactSource}>
                                            {t(`contactSources.${contactSource}`)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="startContractType"
                        render={({field}) => (
                            <TextField
                                data-cy="startContractType"
                                variant="filled"
                                label={t('startContractType')}
                                disabled={isLoading}
                                fullWidth
                                select
                                error={!!errors.startContractType}
                                helperText={errors.startContractType?.message}
                                {...field}
                            >
                                {Object.values(StartContractTypeCare).map(startContractType => {
                                    return (
                                        <MenuItem key={startContractType} value={startContractType}>
                                            {t(`startContractTypes.${startContractType}`)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="registrationDate"
                        render={({field}) => (
                            <DatePicker
                                {...field}
                                disabled={startContractType === StartContractTypeCare.DIRECT_START_WITHOUT_PE
                                    || isLoading}
                                renderInput={params => (
                                    <TextField
                                        data-cy="registrationDate"
                                        {...params}
                                        variant="filled"
                                        label={t('registrationDate')}
                                        error={!!errors.registrationDate}
                                        helperText={errors.registrationDate?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="estimatedRegistrationDate"
                        render={({field}) => (
                            <DatePicker
                                {...field}
                                disabled={isLoading}
                                renderInput={params => (
                                    <TextField
                                        data-cy="estimatedRegistrationDate"
                                        {...params}
                                        variant="filled"
                                        label={t('estimatedRegistrationDateCare')}
                                        error={!!errors.estimatedRegistrationDate}
                                        helperText={errors.estimatedRegistrationDate?.message}
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={12}>
                    <Controller
                        control={control}
                        name="note"
                        render={({field}) => (
                            <TextField
                                data-cy="note"
                                variant="filled"
                                label={t('note')}
                                disabled={isLoading}
                                fullWidth
                                error={!!errors.note}
                                helperText={errors.note?.message}
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
};

AdditionalCareFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    startContractType: PropTypes.string,
    errors: PropTypes.object,
};

AdditionalCareFields.defaultProps = {
    isLoading: false,
    startContractType: '',
    errors: null,
};
