import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import {Box, Button, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {RoutePaths} from '../../../../../../lib/router/route-paths';
import ReactRouterButtonLink from '../../../../../../v1/components/app/Router/ReactRouterButtonLink';
import Alert from '../../../../../../v1/components/ui-kit/Alert/Alert';
import {UiActions} from '../../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../ui/utils/constants';

const DepositOfAccountsPaymentModal = ({paymentInfo}) => {
    const {t} = useTranslation('companies');
    const dispatch = useDispatch();
    const {isOpenFromDashboard} = useSelector(
        UiSelectors.createModalDataSelector(ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT),
    ) ?? {};

    const handleModalClose = () => {
        dispatch(UiActions.setActiveModal(ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT, false));
    };

    return (
        <ModalWrapper
            modalKey={ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT}
            handleCloseCB={() => {
                dispatch(UiActions.setActiveModal(ModalsKeys.DEPOSIT_OF_ACCOUNTS_PAYMENT, false));
            }}
            hasShowClose={true}
            containerSx={{width: '628px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {t('formalities.depositOfAccounts.paymentModal.title')}
                </Typography>
            )}
        >
            <Box
                sx={{
                    display: 'flex', flexDirection: 'column', gap: 2,
                }}
            >
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                    <Typography>
                        <Trans
                            t={t}
                            values={{
                                amount: paymentInfo?.amount,
                            }}
                            i18nKey="formalities.depositOfAccounts.paymentModal.messageWithAmount"
                            components={{bold: <Box component="span" sx={{fontWeight: 500}} />}}
                        />
                    </Typography>
                    <Typography>
                        {t('formalities.depositOfAccounts.paymentModal.messageAboutLink')}
                    </Typography>
                    <Typography>
                        {t('formalities.depositOfAccounts.paymentModal.messageAboutINPI')}
                    </Typography>
                    {!isOpenFromDashboard && (
                        <Alert
                            severity="info"
                            message={(
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                    <span>
                                        <Trans
                                            t={t}
                                            values={{
                                                amount: paymentInfo?.amount,
                                            }}
                                            i18nKey="formalities.depositOfAccounts.paymentModal.penalityMessage"
                                            components={{bold: <Box component="span" sx={{fontWeight: 500}} />}}
                                        />
                                    </span>
                                    <span>
                                        {t('companies:formalities.depositOfAccounts.paymentModal.payLaterMessage')}
                                    </span>
                                </Box>
                            )}
                            elevation={0}
                            sx={{
                                mt: 1,
                                borderRadius: '12px',
                            }}
                        />
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4,
                    }}
                >
                    {isOpenFromDashboard && (
                        <Button
                            fullWidth
                            startIcon={<CloseIcon />}
                            variant="outlined"
                            color="info"
                            onClick={handleModalClose}
                        >
                            {t('formalities.depositOfAccounts.paymentModal.payLater')}
                        </Button>
                    )}
                    {!isOpenFromDashboard && (
                        <ReactRouterButtonLink
                            to={RoutePaths.DASHBOARD}
                            fullWidth
                            startIcon={<InsertChartIcon />}
                            variant="outlined"
                            color="info"
                            onClick={handleModalClose}
                        >
                            {t('formalities.depositOfAccounts.paymentModal.returnToDashboard')}
                        </ReactRouterButtonLink>
                    )}
                    <ReactRouterButtonLink
                        to={paymentInfo?.linkUrl}
                        fullWidth
                        endIcon={<ArrowForwardIcon />}
                        variant="contained"
                        color="error"
                        onClick={handleModalClose}
                        target="_blank"
                    >
                        {t('formalities.depositOfAccounts.paymentModal.payButton')}
                    </ReactRouterButtonLink>
                </Box>
            </Box>
        </ModalWrapper>
    );
};

DepositOfAccountsPaymentModal.propTypes = {
    paymentInfo: PropTypes.object,
};

DepositOfAccountsPaymentModal.defaultProps = {
    paymentInfo: {},
};

export default DepositOfAccountsPaymentModal;
