import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, Button, CircularProgress, Grow, Typography} from '@mui/material';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import SectionStatus from './SectionStatus';
import ReactRouterButtonLink from '../../../../../../v1/components/app/Router/ReactRouterButtonLink';
import Alert from '../../../../../../v1/components/ui-kit/Alert/Alert';
import {getConfig} from '../../../../../../v1/config';
import CategorisationSection from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {AccountingActions} from '../../../accounting/store/accounting.action';
import {AccountingSelector} from '../../../accounting/store/accounting.selector';
import FormalityStatusTag from '../../components/FormalityStatusTag';
import {FORMALITY_SECTION_STATUS} from '../../utils/constants';
import {INPI_SUBMISSION_STATUS} from '../utils/consts';

const STEPS_ORDER = {
    [INPI_SUBMISSION_STATUS.NOT_STARTED]: 0,
    [INPI_SUBMISSION_STATUS.SIGNATURE_PENDING]: 1,
    [INPI_SUBMISSION_STATUS.PAYMENT_PENDING]: 2,
    [INPI_SUBMISSION_STATUS.VALIDATION_PENDING]: 3,
    [INPI_SUBMISSION_STATUS.REJECTED]: 4,
};

const getStepState = (step, inpiSubmissionDetails) => {
    const inpiStatus = inpiSubmissionDetails?.status;
    const retry = inpiSubmissionDetails?.retry;

    switch (step) {
        case INPI_SUBMISSION_STATUS.NOT_STARTED: {
            if (STEPS_ORDER[inpiStatus] > STEPS_ORDER[INPI_SUBMISSION_STATUS.NOT_STARTED]) {
                return STEP_STATE.Completed;
            }

            if (inpiSubmissionDetails?.error || inpiSubmissionDetails?.warnings?.length > 0) {
                if (retry) {
                    return STEP_STATE.Active;
                }

                return STEP_STATE.Error;
            }

            return STEP_STATE.Active;
        }
        case INPI_SUBMISSION_STATUS.SIGNATURE_PENDING: {
            if (STEPS_ORDER[inpiStatus] === STEPS_ORDER[INPI_SUBMISSION_STATUS.SIGNATURE_PENDING]) {
                if (inpiSubmissionDetails?.error || inpiSubmissionDetails?.warnings?.length > 0) {
                    if (retry) {
                        return STEP_STATE.Active;
                    }

                    return STEP_STATE.Error;
                }

                return STEP_STATE.Active;
            }

            if (STEPS_ORDER[inpiStatus] > STEPS_ORDER[INPI_SUBMISSION_STATUS.SIGNATURE_PENDING]) {
                return STEP_STATE.Completed;
            }

            return STEP_STATE.Pending;
        }
        case INPI_SUBMISSION_STATUS.PAYMENT_PENDING: {
            if (STEPS_ORDER[inpiStatus] === STEPS_ORDER[INPI_SUBMISSION_STATUS.PAYMENT_PENDING]) {
                if (inpiSubmissionDetails?.error || inpiSubmissionDetails?.warnings?.length > 0) {
                    if (retry) {
                        return STEP_STATE.Active;
                    }

                    return STEP_STATE.Error;
                }

                return STEP_STATE.Active;
            }

            if (STEPS_ORDER[inpiStatus] > STEPS_ORDER[INPI_SUBMISSION_STATUS.PAYMENT_PENDING]) {
                return STEP_STATE.Completed;
            }

            return STEP_STATE.Pending;
        }
    }
};

const FlowStepINPI = ({isActive, isFocused, status, companyId, annualAccountId}) => {
    const {t} = useTranslation('companies');
    const dispatch = useDispatch();

    const inpiSubmissionDetailsAll = useSelector(AccountingSelector.selectINPISubmission);
    const inpiSubmissionDetails = inpiSubmissionDetailsAll?.[annualAccountId];
    const inpiStatus = inpiSubmissionDetails?.status;

    const inpiInProcess = inpiSubmissionDetails?.init
        || STEPS_ORDER[inpiSubmissionDetails?.status] > 0
        || inpiSubmissionDetails?.retry
        || inpiSubmissionDetails?.error
        || inpiSubmissionDetails?.warnings?.length > 0;

    const steps = [
        {
            id: INPI_SUBMISSION_STATUS.NOT_STARTED,
            label: t(`formalities.depositOfAccounts.flowSteps.inpi.stepLabels.${INPI_SUBMISSION_STATUS.NOT_STARTED}`),
            state: getStepState(INPI_SUBMISSION_STATUS.NOT_STARTED, inpiSubmissionDetails),
        },
        {
            id: INPI_SUBMISSION_STATUS.SIGNATURE_PENDING,
            label: t(`formalities.depositOfAccounts.flowSteps.inpi.stepLabels.${INPI_SUBMISSION_STATUS.SIGNATURE_PENDING}`),
            state: getStepState(INPI_SUBMISSION_STATUS.SIGNATURE_PENDING, inpiSubmissionDetails),
        },
        {
            id: INPI_SUBMISSION_STATUS.PAYMENT_PENDING,
            label: t(`formalities.depositOfAccounts.flowSteps.inpi.stepLabels.${INPI_SUBMISSION_STATUS.PAYMENT_PENDING}`),
            state: getStepState(INPI_SUBMISSION_STATUS.PAYMENT_PENDING, inpiSubmissionDetails),
        },
    ];

    return (
        <Grow in={true}>
            <div>
                <CategorisationSection
                    title={t('formalities.depositOfAccounts.flowSteps.inpi.title')}
                    status={isActive ? FORMALITY_SECTION_STATUS.REQUIRED : FORMALITY_SECTION_STATUS.WAITING_FOR_PAYMENT}
                    SectionStatusComponent={SectionStatus}
                    isActive={isActive}
                    isFocused={isActive && isFocused}
                    statusTranslationSource="companies"
                    statusTranslationPath="accounting.uploadFec.statuses"
                    sx={{width: '100%', maxWidth: '600px'}}
                >
                    {inpiStatus === INPI_SUBMISSION_STATUS.REJECTED && (
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start'}}>
                            <Box sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%'}}>
                                <Box>
                                    <Typography sx={{fontWeight: 500, fontSize: '16px'}}>
                                        {t('formalities.depositOfAccounts.flowSteps.inpi.dateOfCreation')}:{' '}
                                        {moment(inpiSubmissionDetails?.submissionDate).format('DD/MM/YYYY')}
                                    </Typography>
                                    <Typography sx={{fontSize: '12px'}}>
                                        {t('formalities.depositOfAccounts.flowSteps.inpi.formalityId')}:{' '}
                                        {inpiSubmissionDetails?.integrationId}
                                    </Typography>
                                </Box>
                                <FormalityStatusTag status={status} />
                            </Box>
                            <ReactRouterButtonLink
                                to={`${getConfig().inpiUrl}/annual-accounts/${inpiSubmissionDetails?.integrationId}`}
                                variant="contained"
                                endIcon={<OpenInNewIcon />}
                                color="error"
                                target="_blank"
                                sx={{
                                    py: 1,
                                }}
                            >
                                {t('formalities.depositOfAccounts.flowSteps.inpi.buttonRegulateOnInpi')}
                            </ReactRouterButtonLink>
                        </Box>
                    )}
                    {inpiStatus !== INPI_SUBMISSION_STATUS.REJECTED && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={inpiInProcess}
                                    onClick={() => {
                                        dispatch(AccountingActions.sendFormalitiesToINPI({companyId, annualAccountId}));
                                    }}
                                    sx={{
                                        py: 1,
                                    }}
                                >
                                    {t('formalities.depositOfAccounts.flowSteps.inpi.buttonSendFormality')}
                                </Button>
                                <FormalityStatusTag status={status} />
                            </Box>
                            <Box>
                                {inpiInProcess
                                && STEPS_ORDER[inpiStatus] < STEPS_ORDER[INPI_SUBMISSION_STATUS.REJECTED]
                                && (
                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                    <Stepper
                                        activeStep={STEPS_ORDER[inpiStatus]}
                                        sx={{
                                            backgroundColor: 'v2.gray.50',
                                            px: 2,
                                            py: 1,
                                            borderRadius: '8px',
                                        }}
                                    >
                                        {steps.map(step => {
                                            return (
                                                <Step
                                                    key={step.label}
                                                >
                                                    {step.loading && <CircularProgress size={24} />}
                                                    {!step.loading && (
                                                    <StepLabel step={step} />
                                                    )}
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                    {/* Display error  */}
                                    {inpiSubmissionDetails?.error && !inpiSubmissionDetails?.retry && (
                                    <Alert
                                        severity="error"
                                        message={(
                                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, width: '100%'}}>
                                                <Typography>
                                                    <span style={{fontWeight: 500}}>{t('common:error')}:{' '}</span>
                                                    {inpiSubmissionDetails?.error?.message}
                                                </Typography>
                                                <Button
                                                    variant="text"
                                                    startIcon={<RefreshIcon />}
                                                    sx={{color: 'text.primary', alignSelf: 'flex-end', py: 0.5}}
                                                    onClick={() => {
                                                        dispatch(AccountingActions.sendFormalitiesToINPI(
                                                            {companyId, annualAccountId, retry: true},
                                                        ));
                                                    }}
                                                >
                                                    {t('formalities.depositOfAccounts.flowSteps.inpi.buttonTryAgain')}
                                                </Button>
                                            </Box>
                                        )}
                                        sx={{
                                            '& .MuiAlert-message': {
                                                width: '100%',
                                            },
                                            'margin': '0 !important',
                                        }}
                                    />
                                    )}
                                    {inpiSubmissionDetails?.warnings?.length > 0
                                        && !inpiSubmissionDetails?.retry
                                        && !inpiSubmissionDetails?.error && (
                                        <Alert
                                            severity="warning"
                                            message={(
                                                <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, width: '100%'}}>
                                                    {inpiSubmissionDetails?.warnings.map((warning, index) => (
                                                        <Typography
                                                            key={index}
                                                        >
                                                            <span style={{fontWeight: 500}}>{warning.type}:{' '}</span>
                                                            {warning?.message}
                                                        </Typography>
                                                    ))}
                                                    <Button
                                                        variant="text"
                                                        startIcon={<RefreshIcon />}
                                                        sx={{color: 'text.primary', alignSelf: 'flex-end', py: 0.5}}
                                                        onClick={() => {
                                                            dispatch(AccountingActions.sendFormalitiesToINPI(
                                                                {companyId, annualAccountId, retry: true},
                                                            ));
                                                        }}
                                                    >
                                                        {t('formalities.depositOfAccounts.flowSteps.inpi.buttonTryAgain')}
                                                    </Button>
                                                </Box>
                                            )}
                                            sx={{
                                                '& .MuiAlert-message': {
                                                    width: '100%',
                                                },
                                                'margin': '0 !important',
                                            }}
                                        />
                                    )}
                                </Box>
                                )}
                            </Box>
                        </>
                    )}
                </CategorisationSection>
            </div>
        </Grow>
    );
};

FlowStepINPI.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    annualAccountId: PropTypes.string.isRequired,
};

const STEP_STATE = {
    Completed: 'Completed',
    Active: 'Active',
    Pending: 'Pending',
    Error: 'Error',
};

const StepLabel = ({step}) => {
    switch (step.state) {
        case STEP_STATE.Active: {
            return (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress size={24} />
                    <Typography fontWeight={500}>
                        {step.label}
                    </Typography>
                </Box>
            );
        }
        case STEP_STATE.Completed: {
            return (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <CheckCircleIcon sx={{color: 'v2.light.success.light'}} />
                    <Typography>
                        {step.label}
                    </Typography>
                </Box>
            );
        }
        case STEP_STATE.Error: {
            return (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ErrorIcon sx={{color: 'v2.light.error.main'}} />
                    <Typography fontWeight={500}>
                        {step.label}
                    </Typography>
                </Box>
            );
        }
        case STEP_STATE.Pending: {
            return (
                <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Box
                        sx={{display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            color: 'v2.light.info.light',
                            backgroundColor: 'v2.light.primary.shades8'}}
                    >
                        {STEPS_ORDER[step.id] + 1}
                    </Box>
                    <Typography>
                        {step.label}
                    </Typography>
                </Box>
            );
        }
        default:
            return null;
    }
};

StepLabel.propTypes = {
    step: PropTypes.object.isRequired,
};

export default FlowStepINPI;
