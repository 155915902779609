import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Box, Button, IconButton, Typography} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import SectionStatus from './SectionStatus';
import {copyToClipboard} from '../../../../../../utils/copy-to-clipboard';
import CategorisationSection from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import FormalityPaymentStatusTag from '../../components/FormalityPaymentStatusTag';
import {FORMALITY_SECTION_STATUS} from '../../utils/constants';

const FlowStepPaymentStatus = ({isActive, isFocused, annualAccounts}) => {
    const {t} = useTranslation('companies');
    const formalityPaid = true;
    const formalityPaidDate = moment(annualAccounts?.payLinkInfo?.paidAt).format('DD/MM/YYYY');
    const transactionId = annualAccounts?.payLinkInfo?.integrationId;

    return (
        <CategorisationSection
            title={t('accounting.uploadFEC.sections.payment')}
            status={isActive
                ? (isFocused && FORMALITY_SECTION_STATUS.REQUIRED)
                : FORMALITY_SECTION_STATUS.WAITING_FOR_SIGNATURE
            }
            SectionStatusComponent={SectionStatus}
            isActive={isActive}
            isFocused={isActive && isFocused}
            sx={{width: '100%', maxWidth: '600px'}}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'left',
                    gap: 2,
                }}
            >
                <Box
                    sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1}}
                >
                    {!formalityPaid && (
                    <Button
                        variant="contained"
                        endIcon={<ArrowForwardIcon />}
                    >
                        {t('formalities.depositOfAccounts.flowSteps.formalityPayment.buttonResendLink')}
                    </Button>
                    )}
                    {formalityPaid && (
                    <Box sx={{py: 1}}>
                        <Typography sx={{fontWeight: 500}}>
                            {t('formalities.depositOfAccounts.flowSteps.formalityPayment.formalityPaid', {date: formalityPaidDate})}
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                            <Typography sx={{fontSize: '12px'}}>
                                {t('formalities.depositOfAccounts.flowSteps.formalityPayment.transactionId', {id: transactionId})}
                            </Typography>
                            <IconButton
                                sx={{color: 'v2.blueGray.300'}}
                                aria-label="open drawer"
                                onClick={() => copyToClipboard(transactionId)}
                                edge="start"
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    )}
                    <Box>
                        <FormalityPaymentStatusTag status={annualAccounts?.payLinkInfo?.status} />
                    </Box>
                </Box>
            </Box>
        </CategorisationSection>
    );
};

FlowStepPaymentStatus.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    annualAccounts: PropTypes.object.isRequired,
};

export default FlowStepPaymentStatus;
