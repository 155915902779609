import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {FinancialStatementsActionType} from './financial-statements.action-type';
import {FinancialStatementsActions} from './financial-statements.actions';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import hiwayLocalStorage from '../../../v1/config/storage';
import {LoadingActions, LoadingTypes} from '../../loading';
import {SidebarSelectors} from '../../sidebar/store/sidebar.selector';
import {FinancialStatementsApi} from '../api/financial-statements.api';

export const getFinancialStatementsFlow = function* () {
    try {
        const location = yield select(selectRouterLocation);
        const selectedRole = yield select(SidebarSelectors.selectSelectedRole);

        const search = location?.query;

        if (!search?.['financialStatements-year']) {
            return;
        }

        const storageKey = 'financialStatements-savedRowsPerPage';
        const limit = search?.['financialStatements-rowsPerPage']
            ?? (hiwayLocalStorage.has(storageKey) ? parseInt(hiwayLocalStorage.get(storageKey), 10) : 10);

        const params = {
            limit,
            offset: search?.['financialStatements-page']
                ? parseInt(search['financialStatements-page'], 10) * limit
                : 0,
            // NOTE: BE does not support sortBy because of error 500 (Dejan told me it's some SWAN bug)
            /* sortBy: search?.['financialStatements-sortBy']
                ? search['financialStatements-sortBy']
                : undefined,
            sortOrder: search?.['financialStatements-sortBy']
                ? search?.['financialStatements-sortDirection']
                    ? search?.['financialStatements-sortDirection'].toUpperCase()
                    : 'DESC'
                : undefined, */
            searchQuery: search?.['financialStatements-searchTerm'],
            statusFilter: search?.['financialStatements-statusFilter'],
            year: search?.['financialStatements-year'],
            roles: [selectedRole],
        };

        yield put(LoadingActions.setLoading(LoadingTypes.GET_FINANCIAL_STATEMENTS, true));

        const response = yield call(FinancialStatementsApi.getFinancialStatements, params);

        yield put(FinancialStatementsActions.storeFinancialStatements(response));
    } catch (e) {
        Debug.error('financial statements getFinancialStatementsFlow', 'Error: ', {e});
        Toast.error('genericError');
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_FINANCIAL_STATEMENTS, false));
    }
};

export const getFinancialStatementsWorker = function* () {
    yield call(getFinancialStatementsFlow);
};

export const watchFinancialStatementsSaga = function* () {
    yield all([
        takeEvery(FinancialStatementsActionType.GET_FINANCIAL_STATEMENTS, getFinancialStatementsWorker),
    ]);
};
